
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900;100;200;300;400;500;600;700;800;900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.active{
    color:green;
}

:root {
    --line-color: #000000;
  }
